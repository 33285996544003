import { createRouter, createWebHashHistory } from 'vue-router'
import Layout from '@/layout/layoutBar.vue'
import config from '@/config/app'

const routes = [
  {
    path: '/',
    component: Layout,
    redirect: '/home',
    children: [
      {
        path: 'home',
        component: () => import('@/views/home/Home.vue'),
        meta: {
          title: "首页",
          isFooter: true
        }
      },
      {
        path: 'panicBuying',
        component: () => import('@/views/panicBuying/panicBuying.vue'),
        meta: {
          title: "抢购",
          isFooter: true
        }
      },
      {
        path: 'notice/noticeDetail',
        component: () => import('@/views/panicBuying/noticeDetail.vue'),
        meta: {
          title: "公告",
          isBack: true
        }
      },
      {
        path: 'mine',
        component: () => import('@/views/mine/mine.vue'),
        meta: {
          title: "我的",
          isFooter: true
        }
      },
      {
        path: 'myqrcode',
        component: () => import('@/views/myqrcode/myqrcode.vue'),
        meta: {
          title: "推广二维码",
          isBack: true
        }
      },
      {
        path: 'mycoupon',
        component: () => import('@/views/mycoupon/mycoupon.vue'),
        meta: {
          title: "我的优惠券",
          isBack: true
        }
      },
      {
        path: 'collection',
        component: () => import('@/views/collection/collection.vue'),
        meta: {
          title: "收款设置",
          isBack: true
        }
      },
      {
        path: 'mysigning',
        component: () => import('@/views/mysigning/my-signing.vue'),
        meta: {
          title: "我的签约",
          isBack: true
        }
      },
      {
        path: 'distributioncenter',
        component: () => import('@/views/distributioncenter/distribution-center.vue'),
        meta: {
          title: "分销中心",
          isBack: true
        }
      },
      {
        path: 'distributioncenter/teamorder',
        component: () => import('@/views/distributioncenter/pages/teamorder.vue'),
        meta: {
          title: "团队订单",
          isBack: true
        }
      },
      {
        path: 'distributioncenter/myfans',
        component: () => import('@/views/distributioncenter/pages/myfans.vue'),
        meta: {
          title: "我的粉丝",
          isBack: true
        }
      },
      {
        path: 'earnings',
        component: () => import('@/views/distributioncenter/pages/earnings.vue'),
        meta: {
          title: "我的收益",
          isBack: true
        }
      },
      {
        path: 'withdrawal',
        component: () => import('@/views/distributioncenter/pages/withdrawal-records.vue'),
        meta: {
          title: "提现记录",
          isBack: true
        }
      },
      {
        path: 'wallet',
        component: () => import('@/views/distributioncenter/pages/wallet.vue'),
        meta: {
          title: "我的佣金",
          isBack: true
        }
      },
      {
        path: 'top-up-log',
        component: () => import('@/views/distributioncenter/pages/top-up-log.vue'),
        meta: {
          title: "充值记录",
          isBack: true
        }
      },
      {
        path: 'shoplist',
        component: () => import('@/views/shoplist/shoplist.vue'),
        meta: {
          isBack: true
        }
      },
      {
        path: 'shop/detail',
        component: () => import('@/views/shoplist/detail.vue'),
        meta: {
          title: "商品详情",
          isBack: true
        }
      },
      {
        path: 'myDetail',
        component: () => import('@/views/myDetail/myDetail.vue'),
        meta: {
          title: "个人信息",
          isBack: true
        }
      },
      {
        path: 'buyerwarehouse',
        component: () => import('@/views/buyerwarehouse/buyerwarehouse.vue'),
        meta: {
          title: "买方仓库",
          isBack: true
        }
      },
      {
        path: 'expenditure',
        component: () => import('@/views/buyerwarehouse/expenditure.vue'),
        meta: {
          title: "收支详情",
          isBack: true
        }
      },
      {
        path: 'szDetail',
        component: () => import('@/views/szDetail/szDetail.vue'),
        meta: {
          title: "收支详情",
          isBack: true
        }
      },
      {
        path: 'sellerwarehouse',
        component: () => import('@/views/sellerwarehouse/sellerwarehouse.vue'),
        meta: {
          title: "卖方仓库",
          isBack: true
        }
      },
      {
        path: 'commissionedlisting',
        component: () => import('@/views/commissionedlisting/commissionedlisting.vue'),
        meta: {
          title: "委托上架",
          isBack: true
        }
      },
      {
        path: 'myAddress',
        component: () => import('@/views/myAddress/myAddress.vue'),
        meta: {
          title: "我的地址",
          isBack: true
        }
      },
      {
        path: 'address/addOrEdit',
        component: () => import('@/views/myAddress/add-or-update-address.vue'),
        meta: {
          title: "收货地址",
          isBack: true
        }
      },
      {
        path: 'text/text',
        component: () => import('@/views/text/text.vue'),
        meta: {
          title: "协议",
          isBack: true
        }
      }
    ]
  },
  {
    path: '/topup',
    component: () => import('@/views/distributioncenter/pages/top-up.vue'),
    meta: {
      title: "充值",
      isBack: true
    }
  },
  {
    path: '/withdraw',
    component: () => import('@/views/distributioncenter/pages/withdraw.vue'),
    meta: {
      title: "提现",
      isBack: true
    }
  }
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

router.beforeEach((to, from, next) => {
  // document.title = to.meta.title || config.title
  // console.log(config.title)
  // document.title=config.title;
  config.title;
  next()
})

export default router;
